<template>
  <div class="login_container">
    <div class="login">
      <div class="avatar">
        <img src="@/assets/img/logo/fangdian72X72.png" alt="">
      </div>
      <el-form ref="loginFormRef" :model="form" :rules="rules" class="loginForm">
        <el-form-item prop="username">
          <el-input v-model="form.username" prefix-icon="el-icon-user-solid"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" prefix-icon="el-icon-lock" type="password"></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'	
export default {
  data () {
    return {
      form:{
        username:'',
        password:''
      },
      rules:{
        username:[
          {required: true, message:'请输入用户名', trigger: 'blur'},
//        { min: 5, max: 12, message: '长度在 5 到 12 个字符', trigger: 'blur' }
        ],
        password:[
          {required: true, message:'请输入密码', trigger: 'blur'},
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created:function(){
  	//若已经登陆跳转到第一个菜单的link
		if (this.$store.state.userInfo&&this.$store.state.access_token!='') {
        this.$router.push({path: '/home'});
   	}
  },
  methods:{
    resetLoginForm(){
      this.$refs.loginFormRef.resetFields()
    },
    login(){
      let that=this
      this.$refs.loginFormRef.validate(valid=>{
        if(!valid){
          return;
	    	}else{
	    		var url = 'login/adminLogin';
	        let params = {
	            account: this.form.username,
	            password: this.form.password,
	        };
	        this.fd_post(url, params).then((res) => {
	        		console.log(res);
	        		if(res.status){
	        			that.$store.commit('$_setStorage', res.userInfo);
								that.$store.commit('$_setLogin', 1);
								that.$store.commit('$_setAccessToken', res.access_token);
	        			that.$message.success('登录成功');
				  			that.$router.push('/home');
	        		}else{
	        			that.$message.error(res.msg);
	        		}
	        }).catch((err) => {
	            that.$message.error('网络错误');
	        });
        }
      })
    }
  }
};
</script>

<style lang="scss">
@import "./Login.scss";
</style>